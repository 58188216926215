<template>
  <div class="coupons-wrapper">
    <div >
      <div class="_banner">
        <img src="http://pic.eaworks.cn/scbank/uploads/202285/1492924.png" alt="">
      </div>

      <div class="_c_menu clearfix">
        <van-row gutter="12">
          <van-col span="8">
            <span :class="{_current: currTab === 'init'}" @click="onClickTab('init')">未使用</span>
          </van-col>
          <van-col span="8">
            <span :class="{_current: currTab === 'activation'}" @click="onClickTab('activation')">已激活</span>
          </van-col>
          <van-col span="8">
            <span :class="{_current: currTab === 'used'}" @click="onClickTab('used')">已使用</span>
          </van-col>
        </van-row>
      </div>

      <div class="_list">
<!--        @click="onClickPageLink(item.jump_type!=''?item.jump_type!=null?item.jump_type:'/activity?id='+item.id:'/activity?id='+item.id)"-->
        <div class="_item" v-for="item of dataList" >
          <van-row>
            <van-col span="6">
              <div class="img_box">
                <img class="_pic" :src="item.coverImage" alt="">
              </div>
            </van-col>
            <van-col span="17">
              <div class="_name">{{ item.title }}</div>
            </van-col>
          </van-row>
          <span class="_btn" ><img
            src="../status/img/btn_x1.png"></span>
        </div>
      </div>
      <div class="_bottom_fixed">
        <div class="_height"></div>
        <div class="_bottom_back">
          <span class="_back_home" @click="$router.push('/home')"></span>
          <span class="_back_prev" @click="$router.go(-1)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";
export default {
  components: {},
  mixins: [ page_links ],
  data() {
    return {
      currTab: 0,
      dataList: [],
      OrderStatus:'init'

    };
  },

  created() {
    this.onClickTab(1);
  },

  mounted() {
    this.getOrderList()
    this.getJsInfo();
  },

  methods: {
    async getOrderList(){
      // 获取用户第三方订单列表
      let {data} = await this.$ajax.gateway("/api/api_get_user_order_list", {
        "METHOD": "get",
        "OrderStatus": this.OrderStatus
      });
      this.dataList = data;
    },
    async getJsInfo(){
      // 生成网页js签名
      let {data,code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if(code==200){
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ];
      }
      wx.ready(function( ){
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function(res){
        console.info("wx error",res);
      });
    },
    onClickTab(val) {
      if(val !== this.currTab){
        this.currTab = val;
        this.getOrderList();
      }
    },


  }
};
</script>

<style lang="less" scoped>
.coupons-wrapper {
  background: #ebebeb;
  position: relative;

  ._banner {
    img {
      display: block;
      width: 100%;
    }
  }

  ._c_menu {
    text-align: center;
    padding: 0.1rem 0.6rem 0.3rem;
    span {
      display: inline-block;
      width: 100%;
      font-size: 0.3rem;
      background: #f0f0f0;
      line-height: 0.8rem;
      border-radius: 0.8rem;
      color: #a4a4a4;
      border: 0.01rem solid #f0f0f0;
      &._current {
        color: #f66b4a;
        background: #f1dfdb;
        border: 0.01rem solid #f66b4a;
      }
    }
  }

  /* 商家列表 */

  /* 商家列表 */

  ._list {
    padding: 0.1rem 0.4rem 0.2rem;
    min-height: 10rem;

    ._item {
      position: relative;
      margin-bottom: 0.4rem;
      background: #fff;
      padding: 0.2rem;
      border-radius: 0.2rem;
      box-shadow: 0 6px 12px 0 rgba(30, 38, 51, 0.12);

      .img_box{
        padding: 0.2rem 0.2rem 0.2rem 0.2rem ;
      }
      img._pic {
        display: block;
       height: 100%;
        width: 100%;
        //margin-left: 0.2rem;
      }

      ._name {
        padding-top: 0.5rem;
        line-height: 2em;
        font-size: 0.42rem;
      }

      ._desc {
        color: #999;
        font-size: 0.32rem;
      }

      ._btn {
        position: absolute;
        top: 1.25rem;
        right: 0.5rem;
        img {
          width: 1.7rem;
        }
      }
    }
  }
}
</style>
